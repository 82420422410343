<template>
	<div
		class="h-screen flex w-full vx-row no-gutter items-center justify-center"
		id="page-logout"
	>
		<div class="is-loading large dark"></div>
	</div>
</template>

<script>
export default {
  name: 'Logout',
  created() {
    setTimeout(() => {
      this.$store.dispatch('logout');
    }, 1000);
  },
};
</script>
